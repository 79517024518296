<div class="constructo-navtop-area">
    <div class="topbar-area">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 text-left">
                    <ul class="topaddres">
                        <li><span class="fa fa-phone"></span> +48 601 931 754</li>
                        <li><span class="fa fa-envelope"></span>raf545@vp.pl</li>
                    </ul>
                </div>
                <div class="col-sm-6 text-right">
                    <!-- <div class="social-link">
                        <a href="#" class="fa fa-facebook"></a>
                        <a href="#" class="fa fa-twitter"></a>
                        <a href="#" class="fa fa-google"></a>
                        <a href="#" class="fa fa-pinterest"></a>
                        <a href="#" class="fa fa-dribbble"></a>
                        <a href="#" class="fa fa-vine"></a>
                        <a href="#" class="fa fa-linkedin"></a>
                        <a href="#" class="fa fa-rss"></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="constructo-menu-area">
        <nav class="navbar">
            <div class="container">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <a class="navbar-brand" href="index.html"><img src="assets/img/logo.png" alt="firma-bartez.pl"></a>
                </div>
                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul class="nav menu navbar-nav navbar-right">
                         <li class="active"><a href="index.html">HOME</a></li>
                        <li><a href="about">O firmie</a></li>
                        <li><a href="services">Usługi</a></li>
                        <li><a href="projects">Projekty</a></li>
                        <li><a href="references">Referencje</a></li>
                        <li><a href="contact">Kontakt</a></li>
                    </ul>
                </div>
                <!-- /.navbar-collapse -->
            </div>
            <!-- /.container-fluid -->
        </nav>
    </div>
</div>