<section class="constructo-home-area">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 positionstat">
                <div class="home-slider-active">
                    <div class="single-slider text-left">
                        <div class="welcome-text">
                            <h1>Oferta</h1>
                            <h1 class="titlecolor">Nasze usługi</h1>
                            <p>Nasza firma oferuje wiele tzw usług ogólnobudowlanych, </p>
                            <p>zaczynając od prostych prac wykończeniowych a koncząć na kompleksowej budowie budynków pod klucz </p>
                            <div class="welcome-btn">
                                <a href="about" class="btn-start rippler rippler-inverse borderw"><span>Dowiedz się więcej</span></a>
                            </div>
                        </div>
                    </div>
                    <div class="single-slider sliderthree text-center">
                        <div class="welcome-text">
                            <h1>Wykonane</h1>
                            <h1 class="titlecolor"> projekty</h1>
                            <p> Nasza realizacje obejmują zarówno prywatne jak i publicznie zamówienia. </p>
                            <p> Wszystkie zlecenia wykonywane są bardzo profesjonalnie z naciskiem na najdrobniejsze szczegóły o czym świadzą wystawione referencje naszych klientów</p>
                            <div class="welcome-btn">
                                <a href="projects" class="btn-start rippler rippler-inverse borderw"><span>Wykonane projekty</span></a>
                                <a href="references" class="btn-start rippler rippler-inverse mr0 active"><span>Referencje</span></a>
                            </div>
                        </div>
                    </div>
                    <div class="single-slider text-right">
                        <div class="welcome-text">
                            <h1>Skontaktuj</h1>
                            <h1 class="titlecolor">się z nami</h1>
                            <p>Kontakt odbywa się bezpośrednio z właścicielem firmy </p>
                            <p>który jest zawszy otwarty na państwa pytania</p>
                            <div class="welcome-btn">
                                <a href="contact" class="btn-start rippler rippler-inverse borderw"><span>Kontakt</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-subheader></app-subheader>

<app-features-main></app-features-main>

<app-about-main></app-about-main>
<section class="constructo-project-section section-padding">
    <div class="container">
        <div class="row">
            <app-projects-main [simpleView]="true"></app-projects-main>
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <div class="getMore-project">
                    <a href="projects">Galeria zleceń</a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-references-quotations></app-references-quotations>