<app-breadcrumb name="O nas"></app-breadcrumb>
<app-subheader></app-subheader>
<app-about-main></app-about-main>


<section class="constructo-whychoose-us section-padding">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-md-5 text-left">
                <div class="best-feature-title">
                    <h2 class="text-uppercase">Nasze wartości</h2>
                    <img src="assets/img/choline.png" alt="firma-bartez.pl">
                </div>
                <div class="best-features-accoudion">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingOne">
                                <h4 class="panel-title">
                                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                      RZETELNE PLANOWANIE
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                <div class="panel-body">
                                    <p>Zanim jakiekolwiek prace budowlane będą miały miejsce, omawiamy szczegółowo wszystkie etapy przedsięwzięcia wraz z klientem.</p>
                                    <p>Zawsze trzymamy się ustalonych planów dzięki czemu klient jest zawsze zadowolony z końcowego efektu.</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingTwo">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                      SZYBKIE WYKONANIE
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseTwo" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingTwo">
                                <div class="panel-body">
                                    <p>Terminy poszczególnych etapów projektu są uzgadniane z klientem. </p>
                                    <p>W razie jakichkolwiek niejaśności lub problemów kontaktujemy się na bieżąco z inwestorem aby usługa została wykonana bez przestojów</p>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading" role="tab" id="headingThree">
                                <h4 class="panel-title">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                      WSZYSTKO W BUDŻECIE
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                <div class="panel-body">
                                    <p>Każda budowa jest prowadzona według wcześniej zaakceptowanego przez klienta kosztorysu.</p>
                                    <p>Wszystkie koszty są wyszczególnione, dzięki czemu inwestor nie jest zaskoczony nieprzewidzanymi wydatkami.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-5 col-md-offset-2">
                <div class="best-feature-title">
                    <h2 class="text-uppercase">Dlaczego my ?</h2>
                    <img src="assets/img/choline.png" alt="firma-bartez.pl">
                </div>
                <div class="whychoose-us-content">
                    <div class="single-chose">
                        <h4><span class="fa fa-check"></span>Fachowość</h4>
                        <p>Do każdego projektu podchodzimy jak do nowego wyzwania, pełni pasji i zaangażowania. Każde zlecenie jest przez nas indywidualnie rozpatrywane.</p>
                    </div>
                    <div class="single-chose">
                        <h4><span class="fa fa-check"></span>Robimy to z pasją</h4>
                        <p>My po prostu lubimy to co robimy. </p>
                        <p>Do każdego projektu podchodzimy jak do nowego wyzwania, pełni pasji i zaangażowania. Każde zlecenie jest przez nas indywidualnie rozpatrywane.</p>
                    </div>
                    <div class="single-chose">
                        <h4><span class="fa fa-check"></span>Zadowolenie klienta</h4>
                        <p> Dla nas najważniejsze jest wykonanie usługi zgodnie z projektem i zaleceniami inwestora.</p>
                        <p> Z racji naszego dużego doświadczenia, zawsze doradzamy naszym klientom, tak aby finalny projekt spełniał jego oczekiwania.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-references-quotations></app-references-quotations>