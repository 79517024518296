<section class="constructo-features-area section-padding">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-md-3 text-center">
                <div class="single-features">
                    <div class="features-icon">
                        <i class="fa fa-bell fa-2x feature-icon"></i>
                        <div class="ficonh">
                            <i class="fa fa-bell fa-2x feature-icon-focus"></i>
                        </div>
                    </div>
                    <h4>DOKŁADNOŚĆ</h4>
                    <p>Dbamy o perfekycjne wykonanie </p>
                    <p> każdego etapu projektu</p>
                    <p> zwracając uwage na najmniejsze szczegóły.</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 text-center">
                <div class="single-features">
                    <div class="features-icon">
                        <i class="fa fa-truck fa-2x feature-icon"></i>
                        <div class="ficonh">
                            <i class="fa fa-truck fa-2x feature-icon-focus"></i>
                        </div>
                    </div>
                    <h4>Sprzęt</h4>
                    <p>Korzystamy wyłącznie z profesjonalnego sprzętu,</p>
                    <p> który posiada wszystkie wymagane atesty i pozwolenia</p>
                    <p> na prace budowlane w Polsce i Europie.</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 text-center">
                <div class="single-features">
                    <div class="features-icon">
                        <i class="fa fa-users fa-2x feature-icon"></i>
                        <div class="ficonh">
                            <i class="fa fa-users fa-2x feature-icon-focus"></i>
                        </div>
                    </div>
                    <h4>Zespół</h4>
                    <p>Wykwalifikowani pracownicy współpracują ze sobą ok kilku lat</p>
                    <p> tworząc zgraną i wydajną ekipę, </p>
                    <p> który poradzi sobie w każdej sytuacji.</p>
                </div>
            </div>
            <div class="col-sm-6 col-md-3 text-center">
                <div class="single-features">
                    <div class="features-icon">
                        <i class="fa fa-user fa-2x feature-icon"></i>
                        <div class="ficonh">
                            <i class="fa fa-user fa-2x feature-icon-focus"></i>
                        </div>
                    </div>
                    <h4>BEZPIECZEŃSTWO I BHP</h4>
                    <p>Wszyscy pracownicy są przeszkoleni</p>
                    <p> z zakresu bezpieczeństwa i przestrzegania</p>
                    <p> zasad BHP na miejscu pracy.</p>
                </div>
            </div>
        </div>
    </div>
</section>