<app-breadcrumb name="Referencje"></app-breadcrumb>
<app-subheader></app-subheader>

<section>
    <div class="container" style="background-color: #d3d3d347; padding-top: 20px; padding-bottom: 20px;">
        <div class="row">
            <div class="col-xs-12 text-center">
                <img src="assets/files/references/Referencje_2013-03-12_MopsLodz.png"/>
                <hr style="border: 0.1px solid lightgray;">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <img src="assets/files/references/Referencje_2013-06-11_Witkowo.png"/>
                <hr style="border: 0.1px solid lightgray;">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <img src="assets/files/references/Referencje_2015-02-11_ZespolSzkolWPuszczykowie.png"/>
                <hr style="border: 0.1px solid lightgray;">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <img src="assets/files/references/Referencje_2015-09-21_SwietlicaWiejskaKaczkiSrednie.png"/>
                <hr style="border: 0.1px solid lightgray;">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <img src="assets/files/references/Referencje_2015-11-06_LiceumSrodaWlkp.png"/>
                <hr style="border: 0.1px solid lightgray;">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <img src="assets/files/references/Referencje_2018-03-26_ZSPKowalewo.png"/>
                <hr style="border: 0.1px solid lightgray;">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <img src="assets/files/references/Referencje_2018-05-11_StarostwoPowiatoweOstrowWlkp.png"/>
                <hr style="border: 0.1px solid lightgray;">
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 text-center">
                <img src="assets/files/references/Referencje_2020-03-18_SalaWiejskaPacanowice.png"/>
            </div>
        </div>
    </div>
</section>