<section class="constructo-aboutUs-area section-padding">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 text-center">
                <div class="section-title">
                    <h2>O nas</h2>
                    <p>Wszystko co powinniście o nas wiedzieć.</p>
                    <div class="line">
                        <img src="assets/img/line.png" alt="firma-bartez.pl">
                    </div>
                </div>
            </div>
        </div>
        <div class="row padding-top">
            <div class="col-sm-12 col-md-6">
                <div class="aboutUs-img">
                    <img src="assets/img/about-img1.jpg" alt="firma-bartez.pl">
                </div>
                <div class="about-img2">
                    <img src="assets/img/about-img2.jpg" alt="firma-bartez.pl">
                </div>
            </div>
            <div class="col-sm-12 col-md-5 col-md-offset-1">
                <div class="constructo-aboutUs-content">
                    <p>Firma powstała w 2012 roku. Dzięki doświadczeniu zdobytym w kraju i za granicą postanowiłem otworzyć własny biznes, który pozwoliłby mi na oferowanie profesjonalnych usług budowlanych.</p>
                    <p>Początkowo pracowałem wyłącznie jako firma jednoosobowa oferująć drobne usługi remontowe. W wyniku napływu większej ilości zgłoszeń, które również wymagały więszkej ilości pracy, zacząłem zatrudniać nowe osoby.
                        Obecnie posiadam wykwalifikowaną kadrę w liczbie 6 osób, która potrafi sobie poradzić praktycznie z każdym zadaniem.
                    </p>
                    <p>
                        Oferta naszej firmy obejmuje szeroko pojęte usługi budowlane i remontowe. Zajmujemy się zarówno prywatnymi jak i publicznymi zleceniami.
                    </p>
                    
                    <div class="row">
                        <div class="col-sm-6 text-left">
                            <div class="about-list">
                                <ul>
                                    <li>Renowacje</li>
                                    <li>Adaptacje</li>
                                    <li>Remonty</li>
                                    <li>Budowa od podstaw</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-6 text-left">
                            <div class="about-list">
                                <ul>
                                    <li>Wykonczenia wnętrz</li>
                                    <li>Roboty ziemne</li>
                                    <li>Docieplenia</li>
                                    <li>Budowa pod klucz</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>