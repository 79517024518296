<section class="constructo-page-title-area parallaxsection">
    <div class="parallax-windowf" data-parallax="scroll" data-image-src="assets/img/home1.jpg"></div>
    <div class="display-cell">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 text-left">
                    <div class="constructo-page-title">
                        <h1 class="titlecolor">{{ name }}</h1>
                        <ol class="breadcrumb">
                            <li><a routerLink="">Home</a></li>
                            <li class="active">{{ name }}</li>
                        </ol>
                        <img src="assets/img/linew.png" alt="firma-bartez.pl">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>