import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './template/header/header.component';
import { FooterComponent } from './template/footer/footer.component';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';
import { BreadcrumbComponent } from './template/breadcrumb/breadcrumb.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ReferencesComponent } from './pages/references/references.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutMainComponent } from './shared/about-main/about-main.component';
import { ReferencesQuotationsComponent } from './shared/references-quotations/references-quotations.component';
import { FeaturesMainComponent } from './shared/features-main/features-main.component';
import { SubheaderComponent } from './template/subheader/subheader.component';
import { ProjectsMainComponent } from './shared/projects-main/projects-main.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ServicesComponent,
    BreadcrumbComponent,
    ProjectsComponent,
    ReferencesComponent,
    ContactComponent,
    AboutMainComponent,
    ReferencesQuotationsComponent,
    FeaturesMainComponent,
    SubheaderComponent,
    ProjectsMainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }
