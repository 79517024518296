
<div class="container">
    <div class="row">
        <div class="col-xs-12 text-center">
            <div class="section-title">
                <h2>Wykonane projekty</h2>
                <p>Możemy pochwalić się szerokim zakresem wykonywanych robót budowlanych o czym świadczą wykonane do tej pory przedsięwzięcia.</p>
                <div class="line">
                    <img src="assets/img/line.png" alt="firma-bartez.pl">
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="!simpleView">
        <div class="col-xs-12 text-center">
            <div class="isotop-nav">
                <ul>
                    <li class="current" data-filter="*">Wszystkie</li>
                    <li data-filter=".interior">Zamówienia publiczne</li>
                    <li data-filter=".archi">Prywatne inwestycje</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="row isotop-active">
        <div *ngFor="let project of projects" class="col-sm-4 interior">
            <div class="constructo-single-project">
                <div class="project-img-def" [ngClass]="{'project-img': !simpleView}">
                    <img [src]="project.mainPictureUrl" alt="firma-bartez.pl">
                    <div class="pro-hover" *ngIf="!simpleView">
                        <a *ngFor="let pictureUrl of project.pictureUrls" [href]="pictureUrl" class="fa fa-search" [ngClass]="project.folderName" ></a>
                        <a [href]="project.mainPictureUrl" class="fa fa-search" [ngClass]="project.folderName" ></a>
                    </div>
                </div>
                <div class="pro-title">
                    <div class="pro-dat">
                        <p>15</p>
                    </div>
                    <h4>{{project.projectName}}</h4>
                </div>
            </div>
        </div>
    </div>
    
</div>