<section class="constructo-references-area section-padding">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 text-center">
                <div class="section-title">
                    <h2>Referencje</h2>
                    <p>Możemy pochwalić się wieloma pozytywnymi i co bardzo ważne <u>niezależnymi</u> opiniami po ukończonych publicznych zleceniach, które świadczą o pełnym profesjonalizmie firmy.</p>
                    <div class="line">
                        <img src="assets/img/line.png" alt="firma-bartez.pl">
                    </div>
                </div>
            </div>
        </div>
        <div class="row detailst-for">
            <div class="col-xs-12 text-center">
                <div class="imrn-client-details">
                    <i class="fa fa-quote-left"></i>
                    <p>Zamówienia zostały przez Wykonawcę zrealizowane należycie, zgodnie z zasadami sztuki budowlanej</p>
                    <p> i prawidłowo ukończone.</p>
                    <div class="client-name">
                        <h4>Starostwo Powiatowe w Poznaniu</h4>
                        <p>11.02.2015</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 text-center">
                <div class="imrn-client-details">
                    <i class="fa fa-quote-left"></i>
                    <p>Efekt końcowych robót oceniamy dobrze i możemy polecić wykonanie </p>
                    <p> prac budowlanych przez firmę "Bartez".</p>
                    <p> Firma "Bartez" posiada wymagany sprzęt do wykonania prac budowlanych oraz przeszkolonych pracowników budowlanych posiadających wymagane kwalifikacje.</p>
                    <div class="client-name">
                        <h4>Fundacja Rozwoju Lokalnego CIVITAS Pleszew</h4>
                        <p>18.03.2020</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 text-center">
                <div class="imrn-client-details">
                    <i class="fa fa-quote-left"></i>
                    <p>Firma wywiązała się z zawartej umowy z należytą starannością, </p>
                    <p> zgodnie z zasadami sztuki budowlanej.</p>
                    <p> Roboty zostały zakończone w ustalonym terminie - bez uwag.</p>
                    <div class="client-name">
                        <h4>Miejski Ośrodek Pomocy Społecznej w Łodzi</h4>
                        <p>12.03.2013</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 text-center">
                <div class="imrn-client-details">
                    <i class="fa fa-quote-left"></i>
                    <p>Firma wywiązała się z zawartej umowy. Prace budowlane zostały wykonane starannie, zgodnie z zasadami sztuki budowlanej</p>
                    <p> Pracownicy posiadają odpowiednie kwalifikacje, firma dysponuje odpowiednim sprzętem, a realizacja zadania odbyła się terminowo</p>
                    <div class="client-name">
                        <h4>Liceum Ogólnokształcące im. Powstańców Wielkopolskich w Środzie Wlkp.</h4>
                        <p>6.11.2015</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 text-center">
                <div class="imrn-client-details">
                    <i class="fa fa-quote-left"></i>
                    <p>Roboty zostały wykonane z należytą starannością, zgodnie z zasadami sztuki budowlanej. </p>
                    <p>Firma dysponuje odpowiednimi kwalifikacjami w wykonywaniu tego typu prac,</p>
                    <p>posiada niezbędny sprzęt, prace wykonuje terminowo.</p>
                    <div class="client-name">
                        <h4>Urząd Gminy i Miasta Witkowo</h4>
                        <p>6.11.2013</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 text-center">
                <div class="imrn-client-details">
                    <i class="fa fa-quote-left"></i>
                    <p>Roboty zostały zrealizowane zgodnie ze sztuką budowlaną, starannością i</p>
                    <p> prawidłowo ukończone. Wykonawca, w trakcie realizacji robót, służył fachową radą</p>
                    <p>i pomocą w rozwiązaniu wszelkich problemów.</p>
                    <p>Polecamy firmę "Bartez" jako solidnego, doświadczonego i rzetelnego Wykonawcę</p>
                    <div class="client-name">
                        <h4>Urząd Gminy Turek</h4>
                        <p>21.09.2015</p>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 text-center">
                <div class="imrn-client-details">
                    <i class="fa fa-quote-left"></i>
                    <p>Firma wykonała zadanie w terminiem, a jakość wykonanych robót była na wysokim poziomie,</p>
                    <p> dlatego też uważamy, że firma Pana Rafała Mintus jest godna polecenia i gwarantuje dotrzymanie</p>
                    <p>terminów oraz wysoką jakość przy zachowaniu przystępnej ceny</p>
                    <div class="client-name">
                        <h4>Starostwo Powiatowe w Ostrowie Wielkopolskim</h4>
                        <p>11.05.2018</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-10 col-md-offset-1">
                <div class="details-nav">
                    <div class="col-sm-2">
                        <div class="single-img">
                            <img src="assets/img/clients/StarostwoPowiatowePoznan.png" alt="https://www.bip.powiat.poznan.pl">
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="single-img">
                            <img src="assets/img/clients/FundacjaRozwojuLokalnegoCIVITASPleszew.png" alt="Fundacja Rozwoju Lokalnego CIVITAS Pleszew">
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="single-img">
                            <img src="assets/img/clients/MiejskiOsrodekPomocySpolecznejLodz.png" alt="https://mops.uml.lodz.pl/bip/">
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="single-img">
                            <img src="assets/img/clients/LOSrodaWlkp.png" alt="https://losroda.edupage.org">
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="single-img">
                            <img src="assets/img/clients/UrzadGminyIMiastaWitkowo.png" alt="http://www.witkowo.pl">
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="single-img">
                            <img src="assets/img/clients/UrzadGminyTurek.png" alt="http://www.gmina.turek.pl/">
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="single-img">
                            <img src="assets/img/clients/StarostwoPowiatoweOstrowWlkp.png" alt="https://www.powiat-ostrowski.pl">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>