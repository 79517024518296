<section class="constructo-get-quote-area">
    <div class="container">
        <div class="row">
            <div class="col-sm-8 text-left">
                <div class="get-quate-content">
                    <h2>WYKONAJ SWÓJ PROJEKT Z PROFESJONALISTAMI</h2>
                    <p>Zapewniamy fachową usługę wykonania twojego wymarzonego projektu. Dbamy o każdy detal twojego przedsięwzięcia, będąć w stałym kontakcie z inwestorem.</p>
                </div>
            </div>
            <div class="col-sm-4 text-right">
                <div class="get-btn">
                    <a href="contact">Kontakt</a>
                </div>
            </div>
        </div>
    </div>
</section>