import { Component, Input } from '@angular/core';
import { ProjectModel } from './project.model';

declare function configureGallery(filterElement: string): any;
declare function resizeGallery(): any;

@Component({
  selector: 'app-projects-main',
  templateUrl: './projects-main.component.html'
})
export class ProjectsMainComponent {

  @Input() simpleView: boolean = false;

  public projects: ProjectModel[] = [];

  ngOnInit(){
    this.loadProjects();
    setTimeout(() => {
      resizeGallery();
    }, 1000);
  }


  private loadProjects(): void {
    this.projects.push(this.loadProject('Kaczki Średnie - 2015', '2015KaczkiSrednie', 7));
    this.projects.push(this.loadProject('Konarzyce - 2015', '2015Konarzyce', 4));
    this.projects.push(this.loadProject('Puszczykowo - 2015', '2015Puszczykowo', 6));
  }

  private loadProject(projectName: string, folderName: string, numberOfPictures: number) : ProjectModel{
    var project = new ProjectModel();
    project.projectName = projectName;
    project.folderName = folderName;
    project.mainPictureUrl = `assets/img/projects/${folderName}/0.jpg`;

    project.pictureUrls = [];
    if (!this.simpleView) {
        for(var i = 1; i <= numberOfPictures; i++) {
            project.pictureUrls.push(`assets/img/projects/${folderName}/${i}.jpg`);
        }
        configureGallery(`.${folderName}`);
    }

    return project;
  }
  
}
