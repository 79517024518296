<section class="constructo-footer-top" style="padding-top: 40px; padding-bottom: 40px;">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 text-center">
                <div class="footer-top-content">
                    <h2>Zaczynajmy</h2>
                    <p style="margin-bottom: 10px;">Do każdego zlecenia podchodzimy indywidualnie i z pełnym zaangażowaniem. U nas zadowolenie klienta jest prioretytowe.</p>
                    <p>Jeśli zależy Ci na pełnym profesjonalizmie skontaktuj się z nami, a z pewnością się nie zawiedziesz.</p>
                    <a href="contact" class="contat-usf">KONTAKT</a>
                    <a href="about" class="learn-moref">Dowiedz się więcej</a>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="constructo-copyright">
<div class="scroll-top">
    <a href="#" (click)="false" class="top0 fa fa-angle-up"></a>
</div>
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
            </div>
            <div class="col-sm-6 text-right">
                <div class="footer-text">
                    <p>Wykonanie: Sebastian Mintus <a href="mailto:sebastian.mintus@gmail.com">sebastian.mintus@gmail.com</a></p>
                </div>
            </div>
        </div>
    </div>
</div>